
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import React from 'react';

export default function PdfViewer({ filePath ,fileType}) {
    console.log("pdf filepath.........................",fileType,filePath)
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

    const pdfContentType = 'application/pdf';
    const base64toBlob = (data) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr(`data:${pdfContentType};base64,`.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: pdfContentType });
    };

    const url = URL.createObjectURL(base64toBlob(filePath));
    const renderPage = (props) => (
        <>
            {props.canvasLayer.children}
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                }}
            >
                <div
                    style={{
                        color: 'rgba(0, 0, 0, 0.1)',
                        fontSize: `${1 * props.scale}rem`,
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        transform: 'rotate(-45deg)',
                        userSelect: 'none',
                    }}
                >
                    {/* Kotak Education Foundation */}
                    Bandhan Genie
                </div>
            </div>
            {props.annotationLayer.children}
            {props.textLayer.children}
        </>
    );
    // const fullScreen =() =>{
    //     if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
    //         if (document.documentElement.requestFullScreen) {
    //              document.documentElement.requestFullScreen();
    //          } else if (document.documentElement.mozRequestFullScreen) {
    //              document.documentElement.mozRequestFullScreen();
    //          } else if (document.documentElement.webkitRequestFullScreen) {
    //              document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    //          }
    //      } else {
    //          if (document.cancelFullScreen) {
    //              document.cancelFullScreen();
    //          } else if (document.mozCancelFullScreen) {
    //              document.mozCancelFullScreen();
    //          } else if (document.webkitCancelFullScreen) {
    //              document.webkitCancelFullScreen();
    //          }
    //      }
    // }
    return (
        <>


            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <div className="card-body">
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                                    <div
                                        style={{
                                            // border: '1px solid rgba(0, 0, 0, 0.3)',
                                            // height: '750px',
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {/* <button onClick={()=>fullScreen()}>click</button> */}
                                      

                                        <Viewer fileUrl={url}
                                            renderPage={renderPage}
                                            // plugins={[zoomPluginInstance,defaultLayoutPluginInstance]}
                                       
                                        />
                                    </div>

                                </Worker>
                            </div>
                        </div>


                    </div>
                </div>
            </>

        </>

    );
};