import React, { useState, useEffect } from "react";
import Question from "./Question";
import QuestionTitle from "./QuestionTitle";
import { ASSESSMENT, CORRECT_ANSWER, MOCK } from "../../../util/Constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./exam.scss";
import { fetchAnswers } from "../../../services/ApiCaller/assessment/AssessmentSchedule/AssessmentScheduleController";
import { Form } from "react-bootstrap";
// import './../../../features/pages/Assessment/Exam/QuestionAns.scss'

const CorrectAnswer = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
  const { state } = useLocation();
  const [menuCollapse, setMenuCollapse] = useState(
    sidebarCollapsed ? false : true
  );
  const navigate = useNavigate();
  const { courseId, lessonId, userId, sessionId, courseName, moduleName } = useParams();
  const [answers, setAnswers] = useState([]);
  // const [courseNameApi, setcourseNameApi] = useState();
  // const [moduleNameApi, setmoduleNameApi] = useState();

  useEffect(() => {
    fetchUrl();
    fetchTheAnswers();
    console.log("===============", courseId, lessonId, userId);
  }, []);

  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    setCurrentUrl(`/${urlElelement}`);
  };

  const redirectToModules = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    if (urlElelement.toLowerCase() === "onlineexam") {
      navigate("/OnlineExam");
      // navigate(`/Course/${courseName.replace(" ", "-")}/${courseId}`);
    } else {
      // navigate("/Course/CourseView/ModuleContentView");
      // navigate(`/Course/${courseName.replace(" ", "-")}/${courseId}`);
      navigate(`/Course/${courseName.replace(" ", " ")}/${courseId}`);
      // :courseName/:courseId
    }
  };

  const getAssessmentType = () => {
    return parseInt(lessonId) === 0 ? MOCK : ASSESSMENT
  }
  //############################## Fetch Quize or Exam score ######################################
  const fetchTheAnswers = () => {
    const response = fetchAnswers(
      courseId,
      lessonId,
      getAssessmentType(),
      userId,
      sessionId
    );
    response.then(
      (success) => {
        setAnswers(success);
        // setcourseNameApi(success.courseName);
        // setmoduleNameApi(success.setmoduleNameApi);
        // console.log(courseNameApi, "apiiiiico")
      },
      (error) => {}
    );
  };


  const getCourseNameForExam = () => {
    return (answers && answers.length > 0) ? answers[0].courseName : ""
  };

  const getCourseAndModuleNameForQuiz = () => {
    return (answers && answers.length > 0) ? `${answers[0].courseName} : ${answers[0].moduleName}`  : ""
  }

  const decideTitle = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    if (urlElelement.toLowerCase() === "onlineexam") {
      return getCourseNameForExam()
    }else{
      return getCourseAndModuleNameForQuiz()
    }
  }

  return (
    <React.Fragment>
      <div className="answers-parent">
        <div className="gray-600-bb-color border-bottom border-2 m-0 p-0">
          <h4 className="pb-2 mx-2">{CORRECT_ANSWER}</h4>
        </div>
        <div class="container-fluid">
          <QuestionTitle
            title={decideTitle()}
            // titletwo={moduleNameApi ? moduleNameApi : ""}
            time={2795}
            hasTimer={false}
          />

          {/* {answers.length > 0 && answers.map((questionData, index) => {
            return (
              <>
                <div className="row justify-content-between">
                  <div className="col">
                    <div className="Quesbody">
                      <h5 className="question_desc">
                        {index + 1}. {questionData ? questionData.question : ""}
                      </h5>
                    </div>
                  </div>
                </div>
                {questionData.options.map((choiceText, index) => {
                  return (
                    <div key={`inline-${index}`} className="mb-3">
                      <Form.Check
                        onChange={() => {}}
                        label={choiceText}
                        style={{fontSize: 18,color: (questionData.answers.includes(choiceText) && questionData.selectedAnswers.includes(choiceText)) ? 'green' : questionData.selectedAnswers.includes(choiceText) ? 'red': "black" }}
                        type={"radio"}
                        checked={questionData.answers.includes(choiceText)}
                        value={choiceText}
                      />
                    </div>
                  );
                })}
              </>
            );
            // return <Question
            // questionData={questionData}
            // questionSymbol={index+1}
            // type={"Single select MCQ"}
            // qIndex={index}
            // onAnswerSubmit={() => {""}}
            // attemptedAnswer={{answers: questionData.answers}}/>
          })} */}



{answers.length > 0 && answers.map((question, index) => (
        <div key={index}>
          <p>Q: <span style={{textTransform:"math-auto"}}>{question.question}</span> </p>
          <ul>
            {question.options.map((option, optionIndex) => {
              const isSelectedAnswer = question.selectedAnswers[0];
              const correctAnswer = question.answers[0];

              const isCorrect = isSelectedAnswer === correctAnswer;
              let color;

              if (isSelectedAnswer === option && correctAnswer === option) {
                color = '#0c9f0c8f';
              } else if (isSelectedAnswer === option.trim()) {
                color = 'red';
              } else if (correctAnswer === option.trim()) {
                color = '#0c9f0c8f';
              } else {
                color = 'black';
              }

              return (
                <li key={optionIndex} style={{ color, textTransform:"math-auto"}}>
                  {option}
                </li>
              );
            })}
          </ul>
        </div>
         ))}
   
          <button
            className="take-exam correct_anc_close"
            onClick={() => {
              redirectToModules();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CorrectAnswer;
