import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './exam.scss'
import QuizSubmitModal from './QuizSubmitModal';
import Question from './Question';
import QuestionTitle from './QuestionTitle';
import { AssessmentSubmit, storeTempDataOnNext } from '../../../services/ApiCaller/assessment/AssessmentSchedule/AssessmentScheduleController';
import { fromQuiz, getAssessmentType, getTimeInMillies } from '../../../util/Utilities';
import StatusModal from '../../DefaultComponent/appComponent/StatusModal';
import AutoSubmitModal from './AutoSubmitModal';
import { SUBMIT_EXAM, SUBMIT_QUIZ } from '../../../util/Constants';
import moment from 'moment';

export default function StartQuiz(props) {
  const { onBackToTermsAndConditions, userData, viewScore, courseid, quizTotalDuration, lessonid, questions, quizState } = props
  const [loading, setLoading] = useState(false);
  const [submitQuiz, setQuizSubmitStatus] = useState(false)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [answers, setAnswers] = useState([])
  const [showSucces, setShowSuccess] = useState(false)
  const [autoSubmit, setAutoSubmit] = useState(false)

  useEffect(() => {
    // fetchAssessmentDetails()
    
  }, [answers])

  useEffect(() => {
    setAnswers(createEmptyAnswers())
    console.log(currentQuestionIndex, "indexxx")
    
  }, [])

  const createEmptyAnswers = () => {
    return questions.map((item) => ({questionId: item.id, answers: []}))
  
  }


  const onNextButtonClick = () => {
    if(currentQuestionIndex+1 < questions.length){
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    }
 
  }

  const onPreviousButtonClick = () => {
    if(currentQuestionIndex === 0){
      onBackToTermsAndConditions()
    }else{
      setCurrentQuestionIndex(currentQuestionIndex-1)
    }
  }

  const onAnswerSubmit = (index, choiceText, type) => {
    console.log("onAnswerSubmit", type, choiceText)
    storeAnswerTemporary(index, choiceText, type)
  }

  const onTimeFinished=() => {
    
  }


  const storeAnswerTemporary = (index, choiceText, type) => {
    const postBody = {lessonId: parseInt(lessonid), 
      userId: userData.id, 
      questionId:questions[index].id,
      quesType:questions[index].questionType, 
      answers:[choiceText], 
      courseId: parseInt(courseid),
      sessionId: quizState.quizOrExam.sessionId
    }
     console.log(questions[index])
   
    const response = storeTempDataOnNext(postBody);
        response.then(
          (success) => {
            console.log("USER ID USER ID", success)
            answers[index].answers = [choiceText]

            setAnswers([...answers])

            // setUserData(success)
            // fetchAssessmentTime(courseId)
          },
          (error) => {}
        );
  }

  

  const submitTheAssessement = () => {
    console.log(quizState.FROM)
    const data = {data: answers, courseId: parseInt(courseid), lessonId: fromQuiz(quizState.FROM) ? parseInt(lessonid) : 0, 
      type: getAssessmentType(quizState.FROM), userId: userData.id, sessionId: quizState.quizOrExam.sessionId, 
      startDateTime: quizState.quizOrExam.startDateTime, endDateTime: moment().format("YYYY-MM-DD HH:mm:ss")

  }
    const response = AssessmentSubmit(data);
        response.then(
          (success) => {
            // setShowSuccess(true)
            setQuizSubmitStatus(false)
            // setTimeout(()=> {
            //   setShowSuccess(false)
              viewScore(success)
            // }, 1000)
            // {"achievedMarks":6,"totalMarks":5,"lable":"Excellent"}
          },
          (error) => {}
        );
  }
  
  return (

    <>
      {!loading ? (
        <>
          {/* <Banner img={bannerImg} styleCss={'exam_banner'} /> */}
          <div className='q_sec_main'>

            <div className='container-fluid'>
              <QuestionTitle title={`Question No- ${currentQuestionIndex+1}/${questions.length}`} time={quizTotalDuration} hasTimer={true} onTimeFinished={() => setAutoSubmit(true)}/>
            </div>

            <div className='row'>
              <div className='col-12'>
                { (questions.length > 0 && answers.length > 0) && 
                <Question 
                  questionData={questions[currentQuestionIndex]} 
                  questionSymbol={"Q"} 
                  qIndex={currentQuestionIndex}
                  onAnswerSubmit={onAnswerSubmit}
                  attemptedAnswer={answers[currentQuestionIndex]}/>
                  }
                    <div className='q_btn_main_style'>
                    {currentQuestionIndex+1 === questions.length ?
                      <div className='q_btn_inner_style'>
                        <button className='take-exam previous' onClick={() => setQuizSubmitStatus(true)}>Submit</button>
                      </div>
                      :
                      <div className='q_btn_inner_style'>
                          <button className= {currentQuestionIndex === 0 ? "take-exam previous fade_btn" : "take-exam previous"} onClick={() => onPreviousButtonClick()} >Previous</button>
                          <button className='take-exam next' onClick={() => onNextButtonClick()} >Next</button>
                      </div>
                    }
                    </div>
              </div>
            </div>
          </div>

          <QuizSubmitModal
            show={submitQuiz}
            submitTheAssessement={submitTheAssessement}
            title={fromQuiz(quizState.FROM) ? SUBMIT_QUIZ : SUBMIT_EXAM}
            onHide={() => {
              setQuizSubmitStatus(false)
              // dismiss()
            }}
          />

          { autoSubmit &&
            <AutoSubmitModal
            show={autoSubmit}
            submitTheAssessement={submitTheAssessement}
            onHide={() => {
              setAutoSubmit(false)
              // dismiss()
            }}
          />
          }
          {showSucces && <StatusModal></StatusModal>}
          {/* </div> */}
        </>
      ) : (
        ''
        // <Loader />
      )}
    </>
  )
}



// {
//   "assessmentId": "47",
//   "userId": 2796,
//   "sessionId": "1698839422"
//   "data": [
//       {
//           "questionId": 218,
//           "answers": [
//               " Accelerate vehicles on the road"
//           ]
//       },
//       {
//           "questionId": 213,
//           "answers": [
//               " A tool for managing social media accounts"
//           ]
//       },
//       {
//           "questionId": 212,
//           "answers": [
//               "  A lending model exclusively for large "
//           ]
//       },
//       {
//           "questionId": 210,
//           "answers": [
//               " Producing physical goods"
//           ]
//       },
//       {
//           "questionId": 217,
//           "answers": [
//               "Social media guidelines"
//           ]
//       },
//       {
//           "questionId": 214,
//           "answers": [
//               " Cross-border payments and remittances"
//           ]
//       },
//       {
//           "questionId": 216,
//           "answers": [
//               " Know Your Customer"
//           ]
//       },
//       {
//           "questionId": 220,
//           "answers": [
//               " Inadequate regulation"
//           ]
//       },
//       {
//           "questionId": 215,
//           "answers": [
//               " They offer gourmet food delivery services"
//           ]
//       },
//       {
//           "questionId": 206,
//           "answers": [
//               ""
//           ]
//       },
//       {
//           "questionId": 219,
//           "answers": [
//               ""
//           ]
//       },
//       {
//           "questionId": 211,
//           "answers": [
//               ""
//           ]
//       },
//       {
//           "questionId": 208,
//           "answers": [
//               ""
//           ]
//       },
//       {
//           "questionId": 207,
//           "answers": [
//               ""
//           ]
//       },
//       {
//           "questionId": 209,
//           "answers": [
//               ""
//           ]
//       }
//   ],
// }
// https://hindujadev.finlabsindia.org:444/assessment/api/v1/lms_assessment/attempt/mgmt/submit/assignment2