import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import RenderMedia from "./RenderMedia";
import axios from "axios";
import "./course.scss";
const listOfFileTypes = ["image", "jpg", "JPEG", "png", "pdf", "html", "video"];

const ContentAccordian = (props) => {

    const { lessionItem, index, onContentItemClick } = props;


  useEffect(() => {}, []);

    //content filter
    const contentOpen = (data) => {
        return new Promise((resolve, reject) => {
            // http://164.52.204.237:10003/api/v1/lms-content/content/student/getById/0
            axios.get(`content/api/v1/lms-content/content/student/getById/${lessionItem.id}`,
            )
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    console.log("Content Filter Error",)
                    reject(error);
                });
        });
    }

    


  const setIconInLession = () => {
    if (
      lessionItem.type === "png" ||
      lessionItem.type === "jpg" ||
      lessionItem.type === "jpeg"
    ) {
      return <i class="fa-solid fa-image"></i>;
    } else if (lessionItem.type === "docx") {
      return <i class="fa-regular fa-file-lines"></i>;
    } else if (
      lessionItem.type === "avi" ||
      lessionItem.type === "mov" ||
      lessionItem.type === "mp4" ||
      lessionItem.type === "youtube" ||
      lessionItem.type === "mp4" ||
      lessionItem.type === "h5p"
    ) {
      return <i class="fa-solid fa-video"></i>;
    } else if (lessionItem.type === "html" || lessionItem.type === "zip") {
      return <i class="fa-brands fa-html5"></i>;
    } else if (lessionItem.type === "ppt" || lessionItem.type === "pptx") {
      return <i class="fa-regular fa-file-powerpoint"></i>;
    } else if (lessionItem.type === "pdf") {
      return <i class="fa-regular fa-file-pdf" style={{ marginRight: -4 }}></i>;
    } else if (lessionItem.type === "xls" || lessionItem.type === "xlsx") {
      return <i class="fa-regular fa-file-excel"></i>;
    }
    else if (lessionItem.type === "scorm") {
      return <i class="fa-solid fa-file"></i>;
    }
    else if (lessionItem.type === "onedrive") {
      return <i class="fa-solid fa-file"></i>;
    }
  };





    // return (<Accordion.Item eventKey={index}>
    //     <Accordion.Header>
    //         <div className='accordian_header' onClick={()=> contentOpen()}>
    //             <div className='acc_head_left'> {lessionItem.name}</div>
    //             <div className='acc_head_right'>
    //                 <div className='acc_icon'>
    //                     {setIconInLession()}
    //                 </div>
    //                 <div className='module_time'>
    //                     <span>{lessionItem.duration}</span>
    //                 </div>
    //             </div>
    //         </div>
    //     </Accordion.Header>
    //     <Accordion.Body>
    //         <div className='lesson_content_main'>
    //             {/* {(index === activeKey) && lessonData && <RenderComp lessionItem={lessionItem} lessonData={lessonData}></RenderComp>} */}
    //             {(index === activeKey && activeKey != null) &&
    //                 <div className='container-fluid'>
    //                     <div className='pdf-view'>
    //                         <RenderMedia lessionItem={lessionItem} />
    //                     </div>
    //                 </div>
    //             }

    //         </div>
    //     </Accordion.Body>
    // </Accordion.Item>
    // );

    

    return (

        <div className='new_module_box_main'>
            <div className='new_module_box' onClick={()=> {onContentItemClick(lessionItem) 
              contentOpen(lessionItem);
            }}>
                <div className='module_title acc_head_left'><div className="content_visit" style={{width: 6, height: 6, borderRadius: "50%", backgroundColor: lessionItem.visited ? "green" : "red"}}></div>{lessionItem.name}</div>
                <div className='module_icon'>
                    <div className='acc_icon'>
                        {setIconInLession()}
                    </div>
                    <div className='module_time'>
                        <span>{lessionItem.duration}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ContentAccordian;
