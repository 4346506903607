import React from 'react';
import orange from "./../../../template/img/orange.png";
// import onkar from "./../../../template/img/onkar.png";
import onkar from "./../../../template/img/onkar.png";
import goldBatch from "./../../../template/img/gold_batch.png";

import green from "./../../../template/img/green.png";
import radhika from "./../../../template/img/radhika.png";
import silverBatch from "./../../../template/img/silver_batch.png";

import red from "./../../../template/img/red.png";
import naina from "./../../../template/img/naina.png";
import bronezeBatch from "./../../../template/img/broneze_batch.png";

import Rank_1 from './../../../template/img/Rank_1.png';
import Rank_2 from './../../../template/img/Rank_2.png';
import Rank_3 from './../../../template/img/Rank_3.png';
import Rank_4 from './../../../template/img/Rank_4.png';
import Rank_5 from './../../../template/img/Rank_5.png';


const LeaderBoard = (props) => {
    const { item, index } = props;

    const getImage = (index) => {
        if (index === 0) {
            return <>
                <img src={orange} className="card-img-top leader_card_bg" alt="..." />
                <div className='profile_pic_main'>
                    <img src={onkar} className='leder_profile' alt='leader image'></img>
                    {/* <span className='leader_batch'>
                        <img src={goldBatch} alt='batch image'></img>
                    </span> */}
                </div>
            </>
        } else if (index === 1) {
            return <>
                <img src={green} className="card-img-top leader_card_bg" alt="..." />
                <div className='profile_pic_main'>
                    <img src={radhika} className='leder_profile' alt='leader image'></img>
                    {/* <span className='leader_batch'>
                        <img src={silverBatch} alt='batch image'></img>
                    </span> */}
                </div>
            </>
        } else {
            return <>
                <img src={red} className="card-img-top leader_card_bg" alt="..." />
                <div className='profile_pic_main'>
                    <img src={naina} className='leder_profile' alt='leader image'></img>
                    {/* <span className='leader_batch'>
                        <img src={bronezeBatch} alt='batch image'></img>
                    </span> */}
                </div>
            </>
        }
    }

        let imageSrc;
        switch (item.rank.toLowerCase()) {
            case 'rank_1':
                imageSrc = Rank_1;
                break;
            case 'rank_2':
                imageSrc = Rank_2;
                break;
            case 'rank_3':
                imageSrc = Rank_3;
                break;
            case 'rank_4':
                imageSrc = Rank_4;
                break;
            case 'rank_5':
                imageSrc = Rank_5;
                break;
            default:
                imageSrc = onkar;
                break;
        }
    


    return (
        <React.Fragment>
            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 pb-3'>
                <div className="card text-center leader_card">
                    {/* <img src={bgImage} className="card-img-top leader_card_bg" alt="..." />
                <div className='profile_pic_main'>
                    <img src={userImage} className='leder_profile' alt='leader image'></img>
                    <span className='leader_batch'>
                        <img src={batch} alt='batch image'></img>
                    </span>
                </div> */}
                    {/* {getImage(index)} */}

                    {/* <img src={orange} className="card-img-top leader_card_bg" alt="..." /> */}
                    <div className='profile_pic_main'>
                        <img src={imageSrc} className='leder_profile' alt='leader image'></img>
                    </div>

                    <div className="card-body cstm_card_body_style">
                        {/* <h5 className="leader_name">{item.leaderName}</h5> */}
                        <p className='course_completed card_content'><span className='d_value'>{item.name}</span></p>
                        <p className='leader_rank'>{item.rank.replace("_", " ")}</p>
                        <p className='course_completed card_content'>Course Completed : <span className='d_value'>{item.coureCompletedCount}</span></p>
                        <p className='exam_score card_content'>Exam Score : <span className='d_value'>{item.examScore}</span></p>
                        <p className="certificate_earn card_content">Certificates Earned : <span className='d_value'>{item.certificateCount}</span></p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default LeaderBoard;