import React from "react";
import icon from "../../../template/img/exam_sample.png";
import defaultImage from "../../../template/img/finance.png";
import "./exam.scss";
import { center } from "react-dom-factories";
import moment from 'moment';

export default function ExamItem(props) {
  const { selectExamType, courseData } = props;
  return (
    <>
      <div className="col-12 col-sm-12 col-ml-6 col-lg-4 col-xl-4 col-xxl-3 mt-3 mb-2">
        <div className="quiz">
          <div className="quiz-image-container">
            <img src={courseData.coverImage ? courseData.coverImage : icon} />
          </div>
          <div className="quiz-details">
            <span className="exam-title">{courseData.name}</span>
            <span className="examname-title">{courseData.examName}</span>
            <div className="quiz-status">
              <div>
                <i
                  className="fa-regular fa-clock fa-sm"
                  style={{ marginRight: 8 }}
                ></i>
                <span class="mx-10">{courseData.examTime} min</span>
              </div>
              <div>
                <i
                  class="fa-solid fa-circle fa-xs"
                  style={{ color: "green", marginRight: 8 }}
                ></i>
                <span class="mx-10">
                  {courseData.completedPercentage === 100
                    ? "Completed"
                    : "Pending"}
                </span>
              </div>
            </div>
            <button
              className={
                !courseData.examAttend && courseData.completedPercentage === 100  ? `take-exam card_btn` : courseData.examAttend  && courseData.examReattempt ?
                `take-exam card_btn` :  `take-exam card_btn disable`
              }
              onClick={() => {
                // if(courseData.completedPercentage === 100){
                selectExamType(courseData);
                // }
              }}
            >
              Take Exam
            </button>
            {courseData.examAttend && !courseData.examPassed  && (
              <span className="last-oppr">You can reattempt this exam on <br/> { moment(courseData.reattemptDate).format('DD-MM-YYYY')}</span>
            )}
          </div>
        </div>

        {/* </div> */}
      </div>
    </>
  );
}
