import { Box, Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { isNumeric } from "../../../util/Utilities";

export default function CustomBreadCrumbs() {
  const navigate = useNavigate();
  const location = useLocation();
  let pathnames = location.pathname.split("/").filter((x) => x);
  console.log("BEFORE", pathnames)
  pathnames = pathnames.filter((item) => !isNumeric(item) )
  
  pathnames.splice(0, 2);
  
  const { courseName, courseId, lessonId } = useParams();

  const redirectToPath = (path) => {
    if (path.toLowerCase() === "modulecontentview") {
      navigate("ModuleContentView");
    }
  };

  return (
    <Box mt={2} mb={2}>
      <Breadcrumbs aria-label="breadcrumb" separator={"/"}>
        <div className="bread-crumbs-back">
          <i
            class="fa-solid fa-circle-chevron-left"
            style={{ color: "#0D2F55" }}
            onClick={() => {
              navigate("/Course");
            }}
          ></i>
          <p
            className="text-primary text-decoration-underline itemhover mb-0 ms-2"
            onClick={() => {
              navigate(`/Course/${courseName}/${courseId}`);
            }}
          >
            {console.log("ccc",courseName)}
            {courseName.replace("-", "/")}
          </p>
        </div>
        {pathnames.map((value, index) => {
          return (
            <>
              {!isNumeric(value) && <div
                className="col"
                onClick={() => {
                  redirectToPath(value);
                }}
              >
                <p className="text-primary text-decoration-underline itemhover m-0">
                  {value.replace(/%20/g, " ").replace("-", "/")}
                </p>
              </div>}
            </>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
}
