import CryptoJS from 'crypto-js';
const secretKey = '086S53U08R59A16J';

export const encryptData = (data) => {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    return ciphertext;
  };
  
  export function decryptData(encryptedMessage) {
    var keyHex = CryptoJS.enc.Utf8.parse(secretKey);
    var decrypted = CryptoJS.AES.decrypt(encryptedMessage, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    var decryptedText = CryptoJS.enc.Utf8.stringify(decrypted);
    console.log('decryptByAES result:', decryptedText);
    return decryptedText;
}