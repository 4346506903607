import React from "react";

export default function OneDrive(props) {
  const { fileId } = props;
  console.log("idddd",fileId);
  // const embedUrl = "https://onedrive.live.com/embed?resid=4F52D95FEFE68CEC%21133&amp;authkey=!AF8cmW892XJpSsY&amp;em=2&amp;wdAr=1.7777777777777777";

  return (
    <>
      <div className="desktop-view-ppt">

        <iframe src={`${fileId}`+'&action=embedview'} width="100%" height="600px" frameborder="0"></iframe>
      </div>
    </>
  );
}
