import React, { useState, useEffect } from "react";
import Banner from "../../DefaultComponent/appComponent/Banner";
import bannerImg from "./../../../template/img/Certificate.png";
import CertificateList from "./CertificateList";
import demoCert from "./../../../template/img/certificate_sample.jpg";
import "./certificate.scss";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import Loader from "../../DefaultComponent/Loader";
import { fetchCertificateList } from "../../../services/ApiCaller/content/unit";
import { getUserByUserId } from '../../../services/ApiCaller/user/usercontroller';
import { useKeycloak } from '@react-keycloak/web';

const Certificate = () => {
  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const { keycloak } = useKeycloak();
   
  const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
  const [menuCollapse, setMenuCollapse] = useState(
    sidebarCollapsed ? false : true
  );
  const [allCertificate, setAllCertificate] = useState([]);
  const [UserID, setUserID] = useState();
  
  const currentDate = new Date();

  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are zero-based
  const currentYear = currentDate.getFullYear().toString();
  
  // Combine the month and year in the desired format
  const formattedDate = `${currentMonth}-${currentYear}`;
  useEffect(() => {
    fetchUrl();
    fetchModerateUserList()
  }, []);
  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    setCurrentUrl(`/${urlElelement}`);
  };
console.log(UserID, "useridd")
  // Filter List
  const fetchCertificate = (userId) => {
    setAllCertificate([]);
    const Data = JSON.stringify({
      createdDate: formattedDate,
      inUserId: userId,
    //   sessionId: null,
    //   assessmentId: 0,
    //   lessonId: 0,
    //   type: "string",
    //   trainer: true,
    //   statuses: [
    //     "Active",
    //     "Inactive",
    //     "Created",
    //     "Pending_For_Approval",
    //     "Approved",
    //     "Rejected",
    //     "Completed",
    //     "Cancelled",
    //     "Used",
    //     "Not_Used",
    //   ],
    });
    console.log(Data);
    const response = fetchCertificateList(Data);
    response.then(
      (success) => {
        setLoading(false);
        setAllCertificate(success);
      },
      (error) => {
        setLoading(false);
        // setErrorObj(error);
        setAllCertificate([]);
      }
    );
  };
const fetchModerateUserList = () => {
        let filterdata =
          keycloak.authenticated &&
          keycloak.tokenParsed &&
          keycloak.tokenParsed.sub;
        const response = getUserByUserId(filterdata);
        response.then(
          (success) => {
            setUserID(success.id)
            fetchCertificate(success.id);
          },
          (error) => {}
        );
      };
    
  return (
    <React.Fragment>
      <Header />
      <div className=" app">
        {/* Sidebar */}
        <Sidebar url={currentUrl} />
        {/* main comp */}
        <main>
          {!loading ? (
            <>
              {/* page title */}
              {/* <div className='row pageheader justify-content-between'>
                        <div className='col'>
                            <div className='pagetitle'>
                               Calendar
                            </div>
                        </div>
                    </div> */}
              {/* main page  */}
              <div className="mainpage">
                <div class="page_main">
                  <div class="page_inner">
                    <Banner img={bannerImg} styleCss={"cetf_banner"} />
                    <div class="certificate_list_outer">
                    {allCertificate &&
            allCertificate.map((item, index) => {
              return (
          
                      <CertificateList cerImage={demoCert} certificatedata={item}/>
                      );
                    })}
                
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </main>
      </div>
      {/* <Header/>
        <div class="page_main">
                <div class="page_inner">
                <Banner image = {bannerImg} styleCss = {'cetf_banner'}/>
                    <div class="certificate_list_outer">
                        <CertificateList cerImage = {demoCert}/>
                    </div>
                </div>
            </div> */}
    </React.Fragment>
  );
};

export default Certificate;
