import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './exam.scss';
import { getTimeInMillies } from '../../../util/Utilities';
import moment from 'moment';

const Score = (props) => {
    const {onReTakeQuizClick, onCourseContinueClick, quizState, courseName, moduleName, courseid, lessonid, userData} = props
    const navigate = useNavigate();
    const location = useLocation();
    // const [currentTimeInMillis, setCurrentTimeInMillis] = useState(getTimeInMillies())    

    const viewAnswers = () => {
        let urlElements = window.location.href.split('/');
        let urlElelement = (urlElements[3])
        if(urlElelement.toLowerCase() === "onlineexam"){
            navigate(`/OnlineExam/CorrectAnswer/${courseName}/${courseid}/${moduleName}/${lessonid}/${quizState.quizOrExam.sessionId}/${userData.id}`)
        }else{
            navigate(`/Course/CorrectAnswer/${courseName}/${courseid}/${moduleName}/${lessonid}/${quizState.quizOrExam.sessionId}/${userData.id}`)
        }
    }


    const isNavigationFromExam = () => {
        let urlElements = window.location.href.split('/');
        let urlElelement = (urlElements[3])
        // console.log("URL ELEMENT", urlElelement)
        // console.log("URL ELEMENT1", urlElelement.toLowerCase() != "onlineexam")
        // console.log("URL ELEMENT2", urlElelement.toLowerCase() !== "onlineexam")
        return urlElelement
    }

    useEffect(()=>{
        // console.log("quizStatequizStatequizState", quizState)
        // console.log("Path", location)
    }, [])

    // const getExamOrQuizFinishedTime = () => {
    //     const remainingDayTime = getTimeInMillies() - quizState.quizOrExam.sessionId;
    //     return remainingDayTime
    // }
    

    return(
        <React.Fragment>
            <div className='score_main'>
                <p className='score_title'>You Scored</p>
                <p className='score_date score_text_style'>{`Date : ${moment(quizState.quizOrExam.quizResults.date).format("DD/MM/YYYY")}`}</p>
                {/* <p className='score_time score_text_style'>Time taken : {getExamOrQuizFinishedTime()}</p> */}
                <p className={quizState.quizOrExam.quizResults.lable === "Poor" || quizState.quizOrExam.quizResults.lable === "Fail" ? 'final_score score_text_style' : 'final_score passing_score_text_style' }>{`${quizState.quizOrExam.quizResults.achievedMarks}/${quizState.quizOrExam.quizResults.totalMarks}`}</p>
                <p className='score_percentage score_text_style'>{`Percentage - ${quizState.quizOrExam.quizResults.percent}%`}</p>
                <div className='score_button_sec'>
                    {isNavigationFromExam().toLowerCase() === "onlineexam" ? <></> : <button className='retake_btn score_btn_cstm_style' onClick={() => {onReTakeQuizClick()}}>Retake Quiz</button>}
                    <button className='continue_btn score_btn_cstm_style' onClick={() => {onCourseContinueClick()}}>Continue Course</button>
                    <p className='view_correct_ans_link' onClick={()=>{viewAnswers()}}>View Correct Answers</p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Score;