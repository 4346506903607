import React, { useState, useEffect, useContext } from "react";
import Banner from "../../DefaultComponent/appComponent/Banner";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import Loader from "../../DefaultComponent/Loader";
import "./newDashboard.scss";
import Statistic from "./Statistic";
import RecentCourses from "./RecentCourses";

import finance from "./../../../template/img/finance.png";
import banking from "./../../../template/img/banking.png";
import finance2 from "./../../../template/img/plaining.png";

import Reminder from "./Reminders";
import LeaderBoard from "./LeaderBoard";
import { TimeSpent } from "./TimeSpent";

import orange from "./../../../template/img/orange.png";
import onkar from "./../../../template/img/onkar.png";
import goldBatch from "./../../../template/img/gold_batch.png";

import green from "./../../../template/img/green.png";
import radhika from "./../../../template/img/radhika.png";
import silverBatch from "./../../../template/img/silver_batch.png";

import red from "./../../../template/img/red.png";
import naina from "./../../../template/img/naina.png";
import bronezeBatch from "./../../../template/img/broneze_batch.png";
import { useParams, useNavigate } from "react-router-dom";
import * as Actions from "../../../app/state/Actions";
import { useKeycloak } from "@react-keycloak/web";
import { getUserByUserId } from "../../../services/ApiCaller/user/usercontroller";
import { filtergetByUserIdUnit } from "../../../services/ApiCaller/content/unit";
import CourseItem from "../course/CourseItem";
import nodataFound from '../../../template/img/no_data_found.jpg';
import {decryptData} from '../../../../src/util/EncriptionAndDecryption';


import {
  fetchStatistics,
  fetchTheToprPerformers,
} from "../../../services/ApiCaller/admin/schoolsmapping";
import moment from "moment";
import { interventionsview } from "../../../services/ApiCaller/admin/intervention";
import { Controller, useForm } from "react-hook-form";
import CustomDropDown from "../../DefaultComponent/CustomDropDown";
import Select, { components } from "react-select";


const NewDashboard = () => {
  const { courseId } = useParams();

  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [lesson, setLesson] = useState([]);

  const { state, dispatch } = Actions.getStateDispatch(useContext);
  // const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
  // const [menuCollapse, setMenuCollapse] = useState(sidebarCollapsed ? false : true);
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const [userData, setUserData] = useState(null);
  const [stats, setStats] = useState();
  const [interventions, setInterventions] = useState();
  const [topPerformers, setTopPerformers] = useState([]);
  const [courses, setCourses] = useState([]);
  

  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
  } = useForm();

  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const course = watch("course");

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      console.log(value, name, type, "watchhhhh")
    )
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    fetchUrl();
    fetchUserInfo();

    
    // fetchInterventions();
  }, []);
  useEffect(() => {
    if(course){
      fetchTopPerfromers();
    }
    // fetchInterventions();
  }, [course]);

  // useEffect(() => {
  //   fetchTopPerfromers()
  // }, [CourseFilterId]);

  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    console.log("urlElements",urlElements)
    let urlElelement = urlElements[3];
    setCurrentUrl("/NewDashboard");
    //   setCurrentUrl(`/${urlElelement}`)
  };

  const handleLogInOut = () => {
    if (keycloak.authenticated) {
      // props.history.push('/')
      navigate("/")
      keycloak.logout()
      localStorage.removeItem("authenticate")
      localStorage.removeItem("jwt-secret")

    }
  }


  const fetchUserInfo = () => {
    let filterdata =
      keycloak.authenticated &&
      keycloak.tokenParsed &&
      keycloak.tokenParsed.sub;
    const response = getUserByUserId(filterdata);
    response.then(
      (success) => {
        setUserData(success);
        console.log("USER DETAILS", success);
        // fetchCourses(success.id);
        fetchStats(success.id);
        // fetchTimeSpent(success.keycloakUserId)
      if(success.userType.appUrl.includes('admin-app') || success.userType.appUrl.includes('genie_admin')) {
        console.log(success.userType.appUrl, "ll")
        handleLogInOut()
      }
      },
      (error) => { }
    );
  };

  console.log("getValues()getValues()", getValues());

  const fetchInterventions = () => {
    setLoading(true);
    // setAllUnit([]);
    // const response = filterUnit(Data)
    const response = interventionsview();
    response.then(
      (success) => {
        setLoading(false);
        const interventionsData = success.map((intervention) => ({
          label: intervention.interventionName,
          value: intervention.id,
        }));
        setValue("intervention", {
          label: interventionsData[0].label,
          value: interventionsData[0].value,
        });
        if (interventionsData.length > 0) {
          fetchTopPerfromers(interventionsData[0]);
        }
        setInterventions(interventionsData);
      },
      (error) => {
        setLoading(false);
        console.log("Error in fetchCourses NewDashboard", error);
        // setErrorObj(error)
        // setShowpopup(true);
        // setPopup(true)
      }
    );
  };

  const fetchStats = (userId) => {
    setLoading(true);
    // setAllUnit([]);
    // const response = filterUnit(Data)
    const response = fetchStatistics(userId);
    response.then(
      (success) => {
        setLoading(false);
        setStats(success);
        if(success.courseList.length > 0){
          setValue('course', {label: success.courseList[0].name, value: success.courseList[0].id,})
        }
        setCourses(
          success.courseList.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );

        // const chunk = success.slice(0, success.length > 3 ? 3: success.length);
        // setCourses(chunk);
        // setCourseImg(success.coverImage);
      },
      (error) => {
        setLoading(false);
        console.log("Error in fetchStats NewDashboard", error);
        // setErrorObj(error)
        // setShowpopup(true);
        // setPopup(true)
      }
    );
  };

  const fetchTopPerfromers = () => {
    // setLoading(true);
    const response = fetchTheToprPerformers(1, course.label, true);
    response.then(
      (success) => {
        // setLoading(false);CourseFilterId
        setTopPerformers(success);
      },
      (error) => {
        setLoading(false);
        console.log("Error in fetchStats NewDashboard", error);
      }
    );
  };

  // const manageRedirection = (courseItemData) => {
  //   navigate(`CourseView/${courseItemData.id}`, { state: courseItemData });
  // };

  const manageRedirection = (Coursedata, currentIndex) => {
    navigate(`/Course/${Coursedata.name.replace(" ", " ")}/${Coursedata.id}`, {
      state: {
        modules: lesson,
        currentCourse: Coursedata,
        moduleIndex: currentIndex,
      },
    });
  };

  const getSlicedCourses = (courses) => {
    return courses.slice(0, courses.length > 3 ? 3 : courses.length);
  };

  return (
    <React.Fragment>
      <Header />
      <div className=" app">
        {/* Sidebar */}
        <Sidebar url={currentUrl} />
        {/* main comp */}
        <main>
          {!loading ? (
            <>
              {/* page title */}
              {/* <div className='row pageheader justify-content-between'>
                        <div className='col'>
                            <div className='pagetitle'>
                               Calendar
                            </div>
                        </div>
                    </div> */}
              {/* main page  */}
              <div className="mainpage">
                <div class="page_main">
                  <div class="page_inner">
                    <div className="dash_header">
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                          <div className="login_user">
                            <p className="login_user_name">
                              Hello{" "}
                              <span className="loged_name">
                                {userData ? decryptData(userData.name): ""}
                              </span>
                            </p>
                            <p className="log_msg">
                              Let’s learn something new today!
                            </p>
                          </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                          {/* <div className="search_notification_sec">
                            <div className="serach_sec">
                              <input
                                type="text"
                                className="serch_input"
                                placeholder="Search from courses..."
                              ></input>
                              <span className="search_icon">
                                <i class="fa-solid fa-magnifying-glass"></i>
                              </span>
                            </div>
                            <div className="notification_bell">
                              <span>
                                <i class="fa-regular fa-bell"></i>
                              </span>
                            </div>
                          </div> */}
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                          <div className="login_time_sec">
                            <p className="login_time">
                              Log In Time:{" "}
                              <span className="log_time">
                                {state.appReducer.appLoginTime}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* statistic start */}
                    <div className="statistic_main">
                      <div className="section_title">
                        <p>Your Statistic</p>
                      </div>
                      <div className="row">
                        <Statistic
                          stat_no={
                            stats && stats.totalCourse ? stats.totalCourse : "0"
                          }
                          stat_title="Total Courses"
                          icon=<i className="fa-solid fa-book-open open_book"></i>
                          color="#F13B23"
                        />
                        <Statistic
                          stat_no={
                            stats && stats.totalCertificate
                              ? stats.totalCertificate
                              : "0"
                          }
                          stat_title="Total Certificates"
                          icon=<i class="fa-solid fa-graduation-cap"></i>
                          color="#0D2F55"
                        />
                        <Statistic
                          stat_no={
                            stats && stats.pendingExam ? stats.pendingExam : "0"
                          }
                          stat_title="Pending exam"
                          icon=<i class="fa-solid fa-chart-simple"></i>
                          color="#BA222F"
                        />
                      </div>
                    </div>
                    {/* statistic End */}

                    {/* New course start */}
                    <div className="newCourse_main">
                      <div className="section_title">
                        <p>My Recent Courses</p>
                      </div>
                      <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <div className="row">
                            {stats &&
                              getSlicedCourses(stats.courseList).map(
                                (item, index) => {
                                  return (
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-sm-12">
                                      <CourseItem
                                        // image={"data:image/png;base64," + CourseImg}
                                        // courseName={item.name}
                                        module="6"
                                        // duration={item.validity}
                                        currentStatus="100"
                                        completionStatus="100"
                                        manageRedirection={manageRedirection}
                                        description=""
                                        coursedata={item}
                                        index={index}
                                        buttonEnable={true}
                                      />
                                    </div>
                                  );
                                }
                              )}
                            {/* <RecentCourses image = {finance} courseName = 'Financial planning' module = '3' duration='3' currentStatus = '50' completionStatus='50'/>
                                            <RecentCourses image = {banking} courseName = 'Banking' module = '4'  duration='4' currentStatus = '40' completionStatus='40'/>
                                            <RecentCourses image = {finance2} courseName = 'Financial planning' module = '6'  duration='6' currentStatus = '100' completionStatus='100'/> */}
                          </div>
                          <div className="view_all_btn_area">
                            <span className="btn_border"></span>
                            <button
                              className="view_course_btn"
                              onClick={() => navigate("/Course")}
                            >
                              View all
                            </button>
                          </div>
                        </div>
                        {/* Reminder section */}
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                          <div className="reminder_main">
                            <div className="reminder_header">
                              <h5 className="reminder_title">
                                <i class="fa-regular fa-bell me-2"></i>Reminders
                              </h5>
                            </div>
                            <div className="reminder_content">
                              {userData && (
                                <Reminder userData={userData}></Reminder>
                              )}
                              {/* <Reminder
                                icon=<i class="fa-solid fa-square-check"></i>
                                notificationFor="notify_course"
                                title="Financial planning"
                                module="3"
                                time="5"
                              /> */}
                              {/* <Reminder
                                icon=<i class="fa-solid fa-square-pen"></i>
                                notificationFor="notify_exam"
                                title="Financial planning"
                                module="10"
                                time="10"
                              />
                              <Reminder
                                icon=<i class="fa-solid fa-square-check"></i>
                                notificationFor="notify_course"
                                title="Banking"
                                module="3"
                                time="5"
                              />
                              <Reminder
                                icon=<i class="fa-solid fa-square-check"></i>
                                notificationFor="notify_course"
                                title="Banking"
                                module="3"
                                time="5"
                              />
                              <Reminder
                                icon=<i class="fa-solid fa-square-check"></i>
                                notificationFor="notify_course"
                                title="Branch Management"
                                module="3"
                                time="5"
                              />
                              <Reminder
                                icon=<i class="fa-solid fa-square-check"></i>
                                notificationFor="notify_course"
                                title="Branch Management"
                                module="3"
                                time="5"
                              /> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="leaderBoard_outer">
                        <div className="row">
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="leader_left_inner">
                              <div className="leaderBoard_main">
                                <div className="leaderboard_title">
                                  <h5>Leader Board</h5>
                                </div>
                                <div className="leaderboard_sub_heading">
                                  <div className="leaderBoard_left">
                                    <h5>Top performers</h5>
                                  </div>
                                  <div className="leaderBoard_right d-flex">
                                    <div
                                      className="me-4"
                                      style={{ width: 250 }}
                                    >
                                      <label
                                        for="idLeaderGroupName"
                                        className="form-label leaderBoard_select_label"
                                      >
                                        {/* <i class="fa-solid fa-sliders me-2"></i> */}
                                        Select Course
                                      </label>
                                      {/* <select
                                      className="form-select leaderBoard_select"
                                      id="idLeaderGroupName"
                                    >
                                      <option value="1">MFD</option>
                                      <option value="2">Finance</option>
                                      <option value="3">IT</option>
                                    </select> */}

                                      <Controller
                                        control={control}
                                        render={({
                                          field: { onChange, value, name, ref },
                                        }) => {
                                          // const currentSelection = [].find(
                                          //   (c) => c.value === value
                                          // );
                                          const handleInterventions = (
                                            selectedOption
                                          ) => {
                                            onChange(selectedOption);
                                            // setValue("intervention", selectedOption)
                                            // let str;
                                            // if (selectedOption != null) {
                                            //   setCourseFilterId(
                                            //     selectedOption.value
                                            //   );
                                            // } else {
                                            //   str = null;
                                            //   setCourseFilterId(str);
                                            // }
                                          };
                                          return (
                                            <Select
                                              components={{
                                                ValueContainer:
                                                  CustomValueContainer,
                                              }}
                                              // isClearable
                                              styles={CustomDropDown}
                                              className="Selectontrol"
                                              menuColor="red"
                                              inputRef={ref}
                                              value={value}
                                              reset
                                              name={name}
                                              options={courses}
                                              placeholder={
                                                <label
                                                  htmlFor="idCourse"
                                                  className="labelheading"
                                                >
                                                  {""}
                                                </label>
                                              }
                                              onChange={handleInterventions}
                                            />
                                          );
                                        }}
                                        name="course"
                                      />
                                    </div>

                                    {/* <div>
                                    <label
                                      for="idLeaderGroupName"
                                      className="form-label leaderBoard_select_label"
                                    >
                                      <i class="fa-solid fa-sliders me-2"></i>
                                      Select group name
                                    </label>

                                    <Controller
                                      control={control}
                                      render={({
                                        field: { onChange, value, name, ref },
                                      }) => {
                                        // const currentSelection = [].find(
                                        //   (c) => c.value === value
                                        // );
                                        const handleInterventions = (
                                          selectedOption
                                        ) => {
                                          onChange(selectedOption);
                                          // setValue("intervention", selectedOption)
                                          if(selectedOption != null){
                                            fetchTopPerfromers(selectedOption)
                                          }else{

                                          }
                                            
                                        };
                                        return (
                                          <Select
                                            components={{
                                              ValueContainer:
                                                CustomValueContainer,
                                            }}
                                            // isClearable
                                            styles={CustomDropDown}
                                            className="Selectontrol"
                                            menuColor="red"
                                            inputRef={ref}
                                            value={value}
                                            reset
                                            name={name}
                                            options={interventions}
                                            placeholder={
                                              <label
                                                htmlFor="idSelectIntervention"
                                                className="labelheading"
                                              >
                                                {""}
                                              </label>
                                            }
                                            onChange={handleInterventions}
                                          />
                                        );
                                      }}
                                      name="intervention"
                                    />
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                              <div className="leaderBoard_content_card">
                                <div className="row">

                                  {topPerformers.length > 0 ? 
                                    topPerformers.map((item, index) => {
                                      return (
                                        <LeaderBoard
                                          // bgImage={orange}
                                          // userImage={onkar}
                                          // batch={goldBatch}
                                          index={index}
                                          item={item}
                                        // leaderName="Onkar Kole"
                                        // rank="1"
                                        // CourseStatus="3"
                                        // examScore="99"
                                        // certificatesEarn="3"
                                        />
                                      );
                                    }): 
                                    <div className="nodata_found_main">
                                      <img src={nodataFound} className="img-fluid noData_image"></img>
                                    </div>
                                    }

                                  {/* <LeaderBoard
                                    bgImage={green}
                                    userImage={radhika}
                                    batch={silverBatch}
                                    leaderName="Radhika Sharma"
                                    rank="2"
                                    CourseStatus="2"
                                    examScore="97"
                                    certificatesEarn="2"
                                  />
                                  <LeaderBoard
                                    bgImage={red}
                                    userImage={naina}
                                    batch={bronezeBatch}
                                    leaderName="Naina Kapoor"
                                    rank="3"
                                    CourseStatus="1"
                                    examScore="93"
                                    certificatesEarn="1"
                                  /> */}
                                </div>
                                  {(courses.length > 0 && topPerformers.length > 0) &&  <div className="view_all_btn_area">
                                  <span className="btn_border"></span>
                                  <button
                                    className="view_course_btn"
                                    onClick={() => navigate(`/ViewAllLeaderBoard/${course.value}`)}
                                  >
                                    View all
                                  </button>
                                </div> }
                               

                              </div>
                            </div>
                          </div>
                          <div className="col-xs-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="time_spend_main">
                              <div className="timespend_title">
                                <h5>Time Spent (Last 6 Months)</h5>
                              </div>
                              {userData && <TimeSpent data={userData} />}
                            </div>
                          </div>
                        </div>
                        {/* <div className='demo' style={{width: '100%'}}>
                                        <TimeSpent />
                                   </div> */}
                      </div>

                    </div>
                    {/* const {icon, title, module, time} = props; */}

                    {/* New course End */}

                    {/* Content */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </main>
      </div>
      {/* <Header/>
        <div class="page_main">
                <div class="page_inner">
                <Banner image = {bannerImg} styleCss = {'cetf_banner'}/>
                    <div class="certificate_list_outer">
                        <CertificateList cerImage = {demoCert}/>
                    </div>
                </div>
            </div> */}
    </React.Fragment>
  );
};

export default NewDashboard;
