import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notificationService } from "../../../services/ApiCaller/notification";
import { ENV } from "../../../util/env";
import { login, token } from "../../../util/index";

const Reminder = (props) => {
  const { userData } = props;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [listening, setListening] = useState(false);
  let eventSource = undefined;

  useEffect(() => {

    const notificationUrl =ENV.NOTIFICATION_URL + "notification/notification/inapp-notification/" + userData.id;
    const accessToken = token(); // Replace with your actual access token
    if (!listening) {
      eventSource = new EventSource( notificationUrl);
      // eventSource = new EventSource( ENV.NOTIFICATION_URL + "notification/inapp-notification/" + userData.id);
      eventSource.onopen = (event) => {
        console.log("eventsource connection opened");
        setData((old) => [event.data, ...old]);
      };
      eventSource.onmessage = (event) => {
        console.log("eventsource onmessage", event.data);
        setData((old) => [event.data, ...old]);
      };
      eventSource.onerror = (event) => {
        console.log(event.target.readyState);
        if (event.target.readyState === EventSource.CLOSED) {
          console.log("eventsource closed (" + event.target.readyState + ")");
        }
        eventSource.close();
      };
      setListening(true); // setUserListid(success)
    }
    return () => {
      if (eventSource) {
        eventSource.close();
        console.log("eventsource closed");
      }
    };
  }, []);

  const formattedData = Array.isArray(data) ? data.join('') : '';


  return (
    <React.Fragment>
      <div className="reminder_content">
        <div className="reminder_section">
          <div className="reminder_box">
            <div className="reminder_left">
              <span className={`reminder_icon ${"notificationFor"}`}>
                {<i class="fa-solid fa-square-check"></i>}
              </span>
              {/* <div className="reminder_for"> */}
                <div dangerouslySetInnerHTML={{ __html: formattedData }} />
                {/* <h5 className="reminder_text">{"Course"}</h5>
                <p>
                  <span className="reminder_sub_text">
                    Module Quiz
                  </span>{" "}
                  <span>
                    <i class="fa-regular fa-clock ms-3 me-1"></i> {"time"} min
                    ago
                  </span>
                </p> */}
              {/* </div> */}
            </div>
            {/* <div
              className="reminder_right"
              onClick={() => {
                navigate("/Course/CourseView/ModuleContentView");
              }}
            >
              <div className="navigate_to">
                <span className="navigate_icon">
                  <i class="fa-solid fa-angle-right"></i>
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Reminder;
